<template>
  <div class="flex-column flex-gap-8 pb-25 pt-25 pl-2 pr-2">
    <h1 class="text_s text_weight_accent">
      Campaign Info
    </h1>
    <div v-if="workflow?.name">
      <div class="flex-row-between">
        <p class="text_xs color_tertiary">
          Name
        </p>
        <default-button
          title="Open"
          color="transparent"
          form="rectangle"
          size="s"
          @action="goToCampaign"
        />
      </div>
      <div class="flex-row-between mb-15">
        <h2 class="text_s text_weight_accent">
          {{ workflow?.name }}
        </h2>
        <default-label
          size="xs"
          :label-name="capitalize(workflow?.status)"
          :custom-type="workflow?.status === 'active' ? 'success' : 'warning'"
        />
      </div>
      <div>
        <p class="text_xs color_tertiary mb-1 mt-1">
          Steps
        </p>
        <ul class="flex-column flex-gap-8">
          <item-campaign
            v-for="(action, i) in actionsForList"
            :key="i"
            :action="action"
          />
        </ul>
      </div>
    </div>
    <div
      v-else
      class="flex-column-centered flex-gap-8"
    >
      <i class="bx bx-briefcase color_tertiary icon_size_24" />
      <span class="text_bold text_s">
        No Active Campaigns
      </span>
      <span class="color_tertiary text_s">
        Active campaigns will be found here
      </span>
    </div>
    <default-button
      v-if="workflow?.status === 'active'"
      class="margin-center mt-1"
      :title="isActiveWorkflowForProfile ? 'Pause Campaign' : 'Resume Campaign'"
      :icon-name="isActiveWorkflowForProfile ? 'bx-pause' : 'bx-play'"
      color="neutral"
      form="rectangle"
      size="m"
      @action="updateWorkflowStatusForProfile"
    />
    <continue-modal
      :id="profile._id"
      :show.sync="modals.continue"
      :steps="actionsForModal"
      :name="profile.first_name"
      :show-steps="workflow?.sequences_type === 'simple'"
      @resume="resume"
    />
  </div>
</template>
<script>
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import ItemCampaign from '@/components/inboxPage/info/campaign/ItemCampaign';
  import ContinueModal from '@/modals/ContinueModal';

  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';
  import { mapGetters } from 'vuex';

  export default {
    name: 'CampaignInfo',
    components: {
      DefaultButton,
      DefaultLabel,
      ItemCampaign,
      ContinueModal,
    },
    mixins: [capitalizeFilterMixin, fromNowFilterMixin],
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        modals: {
          continue: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        workflowsList: 'workflows/workflowsList',
        space: 'account/space',
        selectedCredential: 'credentials/selectedCredential',
      }),
      isActiveWorkflowForProfile () {
        return this.profile.workflow_status === 'active';
      },
      workflow () {
        return this.workflowsList.find(w => w._id === this.profile.workflow);
      },
      actionsForModal () {
        return this.workflow?.sequence.map(a => {
          a.done = this.profile.done_sequence_hash.includes(a.id);
          return a;
        });
      },
      actionsForList () {
        return this.profile.next_workflow_actions.map((a, i) => {
          a.stepNumber = i + 1;
          a.icon = this.getIcon(a);
          if (a.status === 'unknown') {
            a.status = 'done';
          }
          if (a.name === 'finished') {
            a.name = 'Finished';
            a.status = 'done';
          }
          if (a.status === undefined) {
            a.nextStepDescription = this.getDescriptionForNextStep(a.delay_date);
          }
          return a;
        });
      },
    },
    methods: {
      goToCampaign () {
        this.$router.push({
          name: 'edit-campaign',
          params: {
            id: this.workflow._id,
          },
          query: {
            space: this.space,
            selectedCred: this.selectedCredential._id,
          },
        });
      },
      updateWorkflowStatusForProfile () {
        if (this.isActiveWorkflowForProfile) {
          this.$emit('update-profile-prop', { workflow_status: 'inactive' });
        } else {
          this.workflow.sequences_type === 'advanced' ? this.resume() : this.modals.continue = true;
        }
      },
      resume (index) {
        this.modals.continue = false;
        this.$emit('update-profile-prop', {
          workflow_status: 'active',
          current_workflow_step: index,
        });
      },
      getIcon (action) {
        switch (action.action_type) {
        case 'send_email':
          return 'bx-mail-send';
        case 'li_actions':
          return 'bxs-user-detail';
        case 'li_connect':
          return 'bx-send';
        case 'enrich_profile':
          return 'bx-diamond';
        case 'li_send_message':
          return 'bx-message-detail';
        case 'li_send_inmail':
          return 'bx-message-detail';
        case 'li_connect_by_email':
          return 'bx-send';
        case 'send_webhook':
          return 'bx-sitemap';
        }
        if (action.name === 'finished') {
          return 'bx-check-square';
        }
        if (action.type === 'condition') {
          return 'bx-spreadsheet';
        }
      },
      getDescriptionForNextStep (delayDate) {
        const isActiveCampaign = this.profile.workflow_status === 'active';
        if (isActiveCampaign) {
          return Date.parse(delayDate) > Date.now()
            ? `in ${this.fromNowFull(delayDate)}`
            : 'in queue';
        } else {
          return 'paused';
        }
      },
    },
  };
</script>
