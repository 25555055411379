import { setCustomerly } from './setCustomerly';
import { setLOUIdentify } from './setLOUIdentify';
import { setProfitWell } from './setProfitWell';
import { setVendasta } from './setVendasta';
import { setTagScript } from './setTagScript';

function setBrandScripts (user, account) {
  switch (user.brand) {
    case 'yourleadtool':
      // eslint-disable-next-line no-case-declarations
      const bar = account?.vendasta_bar ? account.vendasta_bar : user.vendasta_bar;
      setVendasta(bar.navigation_url, bar.account_id);
      break;
    case 'hypersphere':
      setProfitWell(user);
      setLOUIdentify(user, account);
      break;
    case 'buzz':
      setProfitWell(user);
      setTagScript(user);
      break;
    case 'salesleadautomation':
      setCustomerly(user);
      break;
  }
}
export { setBrandScripts };
