<template>
  <li class="flex-row-between">
    <div class="flex-row flex-gap-8">
      <p class="text_xs color_tertiary">
        {{ action.stepNumber }}
      </p>
      <i :class="['bx icon_size_16 color_tertiary', action.icon]" />
      <p class="text_xs text_weight_accent">
        {{ action.name }}
      </p>
    </div>
    <div>
      <div
        v-if="action.status"
        class="flex-row flex-gap-8"
      >
        <i
          :class="[
            'bx icon_size_16',
            'color_' + (isDone ? 'success ' : 'danger'),
            isDone ? 'bx-check' : 'bx-x',
          ]"
        />
        <default-label
          v-if="action.type === 'condition'"
          size="xs"
          :label-name="isDone ? 'Positive' : 'Negative'"
          :custom-type="isDone ? 'success' : 'danger'"
        />
      </div>
      <p
        v-else
        class="text_xs color_tertiary"
      >
        {{ action.nextStepDescription }}
      </p>
    </div>
  </li>
</template>
<script>
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  export default {
    name: 'ItemCampaign',
    components: {
      DefaultLabel,
    },
    props: {
      action: {
        type: Object,
        required: true,
      },
    },
    computed: {
      isDone () {
        return this.action.status === 'done';
      },
    },
  };
</script>
