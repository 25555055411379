<template>
  <div class="parent-size flex-column">
    <ul
      v-if="emails.length"
      class="flex-column flex-gap-16"
    >
      <card-emails
        v-for="email in emails"
        :key="email._id"
        :email="email"
        :profile="profile"
        @reply="$emit('reply', $event)"
      />
    </ul>
    <div
      v-else
      class="flex-column-centered parent-size flex-gap-16 color_tertiary"
    >
      <i class="bx bx-envelope icon_size_60" />
      <p class="text_s text_weight_accent">
        You have no emails yet
      </p>
      <default-button
        title="Send first email"
        color="transparent"
        form="rectangle"
        size="s"
        @action="$emit('reply')"
      />
    </div>
    <div class="flex flex-align-items-center margin-top flex-column-centered">
      <default-button
        v-if="showSendEmailBtn"
        title="Send email"
        color="primary"
        form="rectangle"
        size="s"
        @action="$emit('reply')"
      />
    </div>
  </div>
</template>
<script>
  import CardEmails from '@/components/inboxPage/chat/emails/CardEmails';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  export default {
    name: 'ListEmails',
    components: {
      CardEmails,
      DefaultButton,
    },
    props: {
      emails: {
        type: Array,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
      showSendEmailBtn: {
        type: Boolean,
        default: true,
      },
    },
  };
</script>
