<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div class="send-message-step">
    <div
      class="send-message-step__textarea-container"
      @mouseover="showTemplateMenu"
      @mouseleave="isShowTemplateMenu = false"
    >
      <div class="send-message-step__error">
        <span
          v-for="(error, key) in messageError"
          :key="key"
          class="text_xs"
          :class="key === 'warningText' ? 'color_warning' : 'color_danger'"
        >
          <i
            v-if="error"
            class="bx bx-info-circle"
          />
          {{ error }}
        </span>
      </div>
      <textarea
        ref="messageArea"
        v-model="innerMessage"
        :readonly="readOnly"
        placeholder="Type your message"
        class="send-message-step__textarea"
      />
      <template-unlink-menu
        v-show="isShowTemplateMenu"
        class="send-message-step__template-menu"
        :class="{'send-message-step__template-menu_active': isShowTemplateMenu}"
        :template="template"
        @unlink-template="unlinkTemplate"
        @edit-template="editTemplate"
      />
    </div>
    <step-menu
      :brand="brand"
      :video="video"
      :template="template"
      :message="innerMessage"
      type="li_send_message"
      @add-practice="addPractice"
      @add-template-to-area="addTemplate"
      @remove-video="removeVideo"
      @insert-video="insertVideo"
      @unlink-template="unlinkTemplate"
      @choose-video="$emit('choose-video')"
      @choose-template="$emit('choose-template')"
    />
  </div>
</template>
<script>
  import { addTemplateMixin } from '@/mixins/addTemplateMixin.js';
  import StepMenu from '@/components/updateWorkflow/performAction/menuFiles/StepMenu';
  import TemplateUnlinkMenu from '@/components/updateWorkflow/performAction/menuFiles/TemplateUnlinkMenu';

  export default {
    name: 'SendMessageStep',
    components: {
      StepMenu,
      TemplateUnlinkMenu,
    },
    mixins: [addTemplateMixin],
    props: {
      brand: {
        type: String,
        default: '',
      },
      messageError: {
        type: Object,
        default: () => ({
          warningText: '',
          errorText: '',
        }),
      },
      message: {
        type: String,
        default: '',
      },
      error: {
        type: Boolean,
        default: false,
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      template: {
        type: Object,
        default () {
          return {};
        },
      },
      video: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        innerMessage: this.message || '',
        isShowTemplateMenu: false,
      };
    },
    computed: {
    },
    watch: {
      innerMessage () {
        this.$emit('update:message', this.innerMessage);
      },
      message () {
        this.innerMessage = this.message;
      },
      template () {
        if (this.template) {
          this.innerMessage = this.template.text_template;
        }
      },
    },
    created () {
    },
    methods: {
      unlinkTemplate () {
        this.isShowTemplateMenu = false;
        this.$emit('unlink-template');
      },
      editTemplate (template) {
        this.isShowTemplateMenu = false;
        this.$emit('edit-template', template);
      },
      showTemplateMenu () {
        if (this.readOnly) {
          this.isShowTemplateMenu = true;
        }
      },
      addTemplate (template) {
        this.innerMessage = this.addTemplateToArea(this.innerMessage, template);
      },
      insertVideo (video) {
        this.$emit('insert-video', video);
      },
      removeVideo (video) {
        this.$emit('remove-video', video);
      },
      addPractice (item) {
        this.innerMessage = item.message;
      },
    },
  };
</script>
<style lang="scss">
.send-message-step {
  &__textarea {
    resize: none;
    width: 100%;
    border: none;
    padding: 8px 16px;
    min-height: 160px;
    background: var(--neutral-50);
  }
  &__error {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  &__template-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0;
    transition: 0.3s;
    right: 0;
    border-radius: 8px 8px 0px 0px;
    &_active {
      opacity: 1;
    }
  }
  &__textarea-container {
    padding: 12px 0px;
    position: relative;
  }
}
</style>
