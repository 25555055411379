/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
const emailRegex =
/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))/gi;
const httpRegex = /^http(s)?:\/\//;
const urlRegex = /(?!mailto:)(?:(?:http|https|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?:(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[0-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))|localhost)(?::\d{2,5})?(?:(\/|\?|#)[^\s]*)?/ig;
const phoneRegex = (/^[0-9]{5,13}$/i);
const phoneSymbolsRegex = (/^[+ \-()\d]*$/i);
const twitterRegex = (/^@?(\w){1,15}$/);

const searchReplaceRegex = query => new RegExp(query.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'gi');
const isValidPhone = value => {
  const isValidSymbols = phoneSymbolsRegex.test(value);
  if (!isValidSymbols) {
    return false;
  }
  const newPhone = (value && value.match(/[0-9]+/g)?.join('')) || '';
  return phoneRegex.test(newPhone);
};

export {
  emailRegex,
  httpRegex,
  urlRegex,
  phoneRegex,
  phoneSymbolsRegex,
  twitterRegex,
  searchReplaceRegex,
  isValidPhone
};
