<template>
  <section>
    <header-dialogs
      class="pt-2 pr-2 pl-2 pb-1 inbox-page__dialogs-header"
      :search-data="searchData"
      @update-filters="updateSearchHandler('update-filters', $event)"
      @update-query="updateSearchHandler('update-query', $event)"
      @update-group="updateSearchHandler('update-group', $event)"
    />
    <div
      ref="dialogs"
      class="inbox-page__dialogs-body-wrapper"
      @scroll="scrollHandlerThrottle"
    >
      <div
        v-if="profiles.length"
        class="inbox-page__dialogs-body"
      >
        <card-dialogs
          v-for="profile in profiles"
          :key="profile._id"
          class="background_primary"
          :profile="profile"
          :is-hovered="true"
          :is-active="profile._id === $route.params.profileId"
          @click.native="$emit('choose-profile', profile._id)"
          @update-profile="$emit('update-profile', $event)"
          @remove-profile="$emit('remove-profile', $event)"
        />
        <div
          v-show="!loaded"
          ref="vldParent"
          class="vld-parent"
        />
        <default-button
          class="inbox-page__dialogs-body-button"
          color="neutral"
          form="circle"
          size="m"
          icon-name="bx-refresh"
          @action="syncDialogs"
        />
      </div>
      <div
        v-else
        class="flex-column-centered parent-size flex-gap-8 color_tertiary"
      >
        <p class="text_s text_weight_accent">
          Your conversations will be here
        </p>
        <default-button
          title="Sync dialogs"
          icon-name="bx-refresh"
          color="transparent"
          form="rectangle"
          size="s"
          @action="syncDialogs"
        />
      </div>
    </div>
  </section>
</template>
<script>
  import HeaderDialogs from '@/components/inboxPage/dialogs/HeaderDialogs';
  import CardDialogs from '@/components/inboxPage/dialogs/CardDialogs';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  import { syncDialogs } from '@/api/credentialsMethods';
  import { getInboxProfiles } from '@/api/profilesMethods';
  import { throttle } from 'lodash';
  import { mapGetters } from 'vuex';
  import { loaderMixin } from '@/mixins/loaderMixin';

  export default {
    name: 'DialogsInbox',
    components: {
      HeaderDialogs,
      CardDialogs,
      DefaultButton,
    },
    mixins: [loaderMixin],
    props: {
      searchData: {
        type: Object,
        required: true,
      },
      profiles: {
        type: Array,
        required: true,
      },
    },
    data () {
      return {
        hasMoreItems: true,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
    },
    created () {
      this.scrollHandlerThrottle = throttle(this.scrollHandler, 400, { leading: true });
    },
    mounted () {
      this.makeSearch();
    },
    methods: {
      updateSearchHandler (eventType, value) {
        this.$emit(eventType, value);
        this.hasMoreItems = true;
        this.$refs.dialogs.scrollTop = 0;
        this.makeSearch();
      },
      async syncDialogs () {
        try {
          await syncDialogs(this.selectedCredential._id);
          this.$noty.success(
            'Request for syncing the dialog has been successfully sent'
          );
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      async scrollHandler () {
        const shouldSearch =
          this.$refs.dialogs.scrollTop + this.$refs.dialogs.offsetHeight + 150 >=
          this.$refs.dialogs.scrollHeight && this.hasMoreItems;
        if (shouldSearch) {
          this.makeSearch(true);
        }
      },
      async makeSearch (lazyload = false) {
        if (!this.loaded) { // иначе при интенссивном скролле будет ддос инбокса
          return;
        };
        try {
          this.loaded = false;
          const statisticsCategory =
            this.searchData.filters.chosenData.statistics;
          const withOpenedEmail = statisticsCategory
            ? statisticsCategory.includes('with_opened_email')
            : false;
          const withBouncedEmail = statisticsCategory
            ? statisticsCategory.includes('with_bounced_email')
            : false;
          const chosenFiltersToJSON = Object.entries(this.searchData.filters.chosenData).reduce((acc, [k, v]) => {
            acc[k] = JSON.stringify(v);
            return acc;
          }, {});
          const newProfiles = await getInboxProfiles(
            this.selectedCredential._id,
            this.searchData.query,
            chosenFiltersToJSON,
            this.searchData.group,
            withOpenedEmail,
            withBouncedEmail,
            lazyload ? this.profiles.length : 0
          );
          this.hasMoreItems = !!newProfiles.length;
          const updatedProfiles = lazyload
            ? [...this.profiles, ...newProfiles]
            : newProfiles;
          this.$emit('update:profiles', updatedProfiles);
        } catch (e) {
          this.$noty.error(e.message);
        } finally {
          this.loaded = true;
        }
      },
    },
  };
</script>
