<template>
  <header class="flex-row-between">
    <div class="flex-row flex-gap-8">
      <default-avatar
        size="s"
        :photo-link="profile.photo_link"
        :status-color="profileStatusColor"
      />
      <div class="flex-column flex-gap-4">
        <p class="text_s text_weight_accent">
          {{ profile.first_name + ' ' + profile.last_name }}
        </p>
        <custom-switchers-list
          class="flex flex-gap-4"
          button-type="circle"
          :button-style="{
            padding: '4px 12px',
          }"
          :list="switcherList"
          :selected="activeMessagesGroup"
          @select="$emit('update:activeMessagesGroup', $event)"
        />
      </div>
    </div>
    <div class="flex flex-gap-8 margin-left flex-align-items-center">
      <default-button
        v-if="profileIdFromInbox"
        class="inbox-page__button"
        title="Open with Inbox"
        form="ellipse"
        color="primary"
        size="s"
        @action="goToInbox"
      />
      <default-tooltip
        data-closable="profileActions"
        :items="profileActions"
        list-position="right"
        :has-triangle="false"
        :is-fulled="true"
        @choose="profileActionsHandler"
      >
        <template #default>
          <default-button
            icon-name="bx-dots-vertical-rounded"
            color="tertiary"
            form="square"
            size="m"
          />
        </template>
      </default-tooltip>
    </div>
    <input-modal
      name="forwardModal"
      title="Forward to"
      primary-button-text="Forward"
      :show.sync="modals.forward"
      :tags.sync="recentlyUsedEmails"
      placeholder="Email"
      height="auto"
      @send="sendForward"
    />
  </header>
</template>

<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import variables from '@/styles/_variables.module.scss';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import CustomSwitchersList from '@/components/base/CustomSwitchersList';
  import DefaultTooltip from '@/components/base/uiKit/DefaultTooltip';
  import InputModal from '@/modals/inputModal';

  import { usedEmailsMixin } from '@/mixins/usedEmailsMixin';
  import { emailRegex } from '@/data/regexes';
  import { mapGetters } from 'vuex';
  import { forwardToEmail } from '@/api/profilesMethods';

  const profileActionsData = [
    {
      name: 'Mark as Lead',
      value: 'interested',
      iconName: 'bx-label',
    },
    {
      name: 'Mark as Customer',
      value: 'lead',
      iconName: 'bx-user',
    },
    {
      name: 'Not interested',
      value: 'not_interested',
      iconName: 'bx-block',
    },
    {
      name: 'Forward to email',
      value: 'forward',
      iconName: 'bx-envelope',
    },
  ];

  export default {
    name: 'HeaderInbox',
    components: {
      DefaultAvatar,
      DefaultButton,
      CustomSwitchersList,
      DefaultTooltip,
      InputModal,
    },
    mixins: [usedEmailsMixin],
    props: {
      activeMessagesGroup: {
        type: String,
        required: true,
      },
      hasOldMessagesButton: {
        type: Boolean,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
      profileIdFromInbox: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        switcherList: [
          { value: 'messages', name: 'LinkedIn' },
          { value: 'emails', name: 'Emails' },
        ],
        modals: {
          forward: false,
        },
      };
    },
    computed: {
      ...mapGetters({
        user: 'user/user',
        selectedCredential: 'credentials/selectedCredential',
      }),
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
      profileActionsData () {
        return profileActionsData
          .filter(i => {
            if (
              (!['interested', 'lead'].includes(this.profile.tag) && i.value === 'not_interested') ||
              (['yourleadtool'].includes(this.user.brand) && i.value === 'email') ||
              (this.profile.tag === i.value)
            ) {
              return false;
            }
            return true;
          });
      },
      profileActions () {
        const actions = [...this.profileActionsData];
        if (this.hasOldMessagesButton && this.activeMessagesGroup === 'messages') {
          actions.push({
            name: 'Show old messages',
            value: 'old_messages',
            iconName: 'bx-message',
          });
          return actions;
        } else return actions;
      },
    },
    methods: {
      profileActionsHandler (item) {
        switch (item.value) {
        case 'interested':
        case 'lead':
        case 'not_interested':
          this.$emit('update-profile-prop', { status: item.value });
          break;
        case 'forward':
          this.modals.forward = true;
          break;
        case 'old_messages':
          this.$emit('update:hasOldMessagesButton', false);
        }
      },
      sendForward (email) {
        if (!emailRegex.test(email)) {
          return this.$noty.error('Email is wrong');
        }
        try {
          if (!this.recentlyUsedEmails.includes(email)) {
            this.recentlyUsedEmails.push(email);
          }
          forwardToEmail(this.profile._id, this.selectedCredential._id, email);
          this.$noty.success('Successfully forwarded to email');
        } catch (e) {
          this.$noty.error(e.message);
        }
      },
      goToInbox () {
        this.$router.push({
          name: 'inbox',
          params: {
            profileId: this.profileIdFromInbox,
          },
        });
      },
    },
  };
</script>
