/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getUser () {
  const data = await useHttp(
    '/users/fetch_user',
    'GET'
  );
  data.payload.promp = {
    campaign: data.payload.brand === 'divo',
    csvDownload: data.payload.brand === 'divo',
    gmailIntegrations: data.payload.brand === 'divo',
    bccEmail: data.payload.brand === 'divo',
    outlookIntegration: data.payload.brand === 'divo',
    linkedinTargetEvent: data.payload.brand === 'divo',
    linkedinTargetPost: data.payload.brand === 'divo',
  };
  return data.payload;
}

async function joinToSpace (invite) {
  const data = await useHttp(
    '/users/join_to_space',
    'GET',
    null,
    {
      invite,
    }
  );

  return data.payload;
}

async function exitFromSpace (userId, spaceId) {
  const data = await useHttp(
    `/users/${userId}/exit_from_space`,
    'POST',
    {
      space_id: spaceId,
    }

  );

  return data.payload;
}

async function getSpaces () {
  const data = await useHttp(
    '/users/fetch_spaces',
    'GET'
  );

  return data.payload;
}

async function changeAdmin (userId, spaceId, isAdmin) {
  const data = await useHttp(
    `/users/${userId}/change_admin`,
    'POST',
    {
      space_id: spaceId,
      is_admin: isAdmin,
    }
  );

  return data.payload;
}

async function userExists (email) {
  const data = await useHttp(
    '/users/exists',
    'POST',
    {
      email,
    }
  );

  return data.payload;
}

async function setCredentials (id, spaceId, credentials) {
  const data = await useHttp(
    `/users/${id}/set_credentials`,
    'POST',
    {
      space_id: spaceId,
      credentials: JSON.stringify(credentials),
    }
  );

  return data.payload;
}

async function getAvailableCredentials (userId) {
  const data = await useHttp(
    '/users/get_available_credentials',
    'GET',
    null,
    {
      user_id: userId,
    }
  );

  return data.payload;
}

async function updateUser (user) {
  const data = await useHttp(
    '/users/update',
    'POST',
    user,
    null,
    true
  );

  return data.payload;
}

async function uploadAvatar (file) {
  const data = await useHttp(
    '/users/save_photo',
    'POST',
    {
      file,
    }
  );
  return data.payload;
}

async function changePassword (passwords) {
  const data = await useHttp(
    '/users/change_password',
    'POST',
    passwords,
    null,
    true
  );
  return data.payload;
}

async function changeNotifications (notifications) {
  const data = await useHttp(
    '/users/save_notifications',
    'POST',
    notifications,
    null,
    true
  );
  return data.payload;
}

async function setSpace (spaceId) {
  const data = await useHttp(
    '/users/set_space',
    'POST',
    {
      space_id: spaceId,
    }
  );

  return data.payload;
}

async function getUserList (brand, skip = 0, limit = 10, query = '') {
  const data = await useHttp(
    '/admin/users',
    'GET',
    null,
    {
      brand,
      skip,
      limit,
      query,
    }
  );

  return data.payload;
}

async function createUser (user) {
  const data = await useHttp(
    '/admin/users',
    'POST',
    null,
    user
  );

  return data.payload;
}


export {
  getUser,
  joinToSpace,
  getSpaces,
  exitFromSpace,
  changeAdmin,
  userExists,
  setCredentials,
  getAvailableCredentials,
  updateUser,
  uploadAvatar,
  changePassword,
  changeNotifications,
  setSpace,
  getUserList,
  createUser
};
